<template>
  <div v-if="active" class="form-builder">
    <iframe ref="iframe" :src="url" title="Form Builder" @load="load"></iframe>
    {{ saving }}
    <div v-if="isLoaded" class="form-buttons">
      <a-button :loading="saving" :disabled="isSupervisor" class="form-button" :class="{ 'form-button-disabled': isSupervisor }" @click="save">{{ $t('components.labels.saveForm') }}</a-button>
        <a-popconfirm
          :title="$t('components.notifications.areYouSureYouWantToExitThisForm')"
          :ok-text="$t('components.description.saveAndExit')"
          :cancel-text="$t('components.description.dontSaveAndExit')"
          :ok-button-props="{ props: { disabled: isSupervisor } }"
          @confirm="save"
          @cancel="close">
          <a-button :disabled="saving" class="form-button">{{ $t('values.exit') }}</a-button>
        </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'QuestionnaireFormBuilder',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true,
      default: () => {}
    },
    onComplete: {
      type: Function,
      required: true,
      default: () => {}
    },
    builtForm: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      url: `${process.env.VUE_APP_BUILD_SCHEME || 'http'}://${process.env.VUE_APP_BUILD_HOST || 'http://odk.smartplus.localhost'}`,
      isLoaded: false,
      saving: false
    };
  },
  computed: {
    ...mapGetters(['isRandom']),
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  created() {
    window.onmessage = ({ data }) => {
      if (data.name === 'builder_result') {
        this.saving = true;
        this.onComplete(data.payload).finally(() => {
          this.saving = false;
        });
      }
    };
  },
  destroyed() {
    window.onmessage = undefined;
  },
  methods: {
    save() {
      if (this.saving || this.isSupervisor) {
        return;
      }
      this.saving = true;
      this.$refs.iframe.contentWindow.postMessage({
        name: 'export_xml'
      }, '*');
      this.saving = false;
    },
    load() {
      const messageKey = 'form-builder';
      this.$message.success({ content: 'Loaded', key: messageKey });
      const { builtForm } = this;
      this.$refs.iframe.contentWindow.postMessage({
        name: 'indicator',
        payload: builtForm
      }, '*');
      this.isLoaded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-builder {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 62px);
  z-index: 2;
}

iframe {
  width: 100%;
  height: 100%;
}

.form-buttons {
  position: fixed;
  right: 20px;
  top: 100px;
  display: flex;
}

.form-button {
  width: 150px;
  height: 48px;
  color: #fff;
  border-radius: 1px;
  background: #363534;
  margin: 0 10px;
}

.form-button-disabled {
  opacity: 0.5;
}
</style>
